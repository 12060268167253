window.checkForm = (formId) => {
  resetErrors(formId, ['name', 'isCompany', 'email', 'message', 'agree']);
  const name = getInputByFieldName(formId, 'name');
  if(!name?.value){
    const error = getErrorByFieldName(formId, 'name')
    error.innerText = "Please insert your name";
    error.style.display = "block";
    return;
  }
  const isCompany = getInputByFieldName(formId, 'isCompany');
  if(!isCompany?.value){
    const error = getErrorByFieldName(formId, 'isCompany')
    error.innerText = "Please answer the question";
    error.style.display = "block";
    return;
  }
  const email = getInputByFieldName(formId, 'email');
  if(!email?.value || !validateEmail(email?.value)){
    const error = getErrorByFieldName(formId, 'email')
    error.innerText = "Please insert an valid email";
    error.style.display = "block";
    return;
  }
  const message = getInputByFieldName(formId, 'message');
  if(!message?.value){
    const error = getErrorByFieldName(formId, 'message')
    error.innerText = "Please insert a message";
    error.style.display = "block";
    return;
  }
  const agree = getInputByFieldName(formId, 'agree');
  if(!agree?.checked){
    const error = getErrorByFieldName(formId, 'agree')
    error.innerText = "You have to agree with terms to proceed";
    error.style.display = "block";
    return;
  }
  window.location.href = `mailto:main@claromarques.com?subject=${name.value}&body=${message.value}.`;
}

window.validateEmail = (email) => email.match(
/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);


window.resetErrors = (formId, fields) => {
  fields.forEach(field => {
    const error = getErrorByFieldName(formId, field);
    error.innerText = '';
    error.style.display = "none";
  });
}

window.getInputByFieldName = (formId, fieldName) => 
  document.querySelector(`#${formId} input[name='${fieldName}']`);

window.getErrorByFieldName = (formId, fieldName) => 
  document.querySelector(`#${formId} #error-${fieldName}`);

